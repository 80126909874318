@font-face {
    font-family: 'Rubik-Regular';
    src: url('fonts/Rubik-Regular.ttf');
}
@font-face {
    font-family: 'Rubik-Medium';
    src: url('fonts/Rubik-Medium.ttf');
}
@font-face {
    font-family: 'Rubik-Light';
    src: url('fonts/Rubik-Light.ttf');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('fonts/Roboto-Medium.ttf');
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    font-family: 'Rubik-Regular';
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
}

.plugin {
    background-color: var(--neutral-grey-20);

    .warning-banner {
        .warning-icon-wrapper {
            img {
                padding-right: 8px;
            }
        }

        .call-text {
            color: #ff381b;

            .bold {
                font-weight: 600;
            }
        }
    }

    &-loading,
    &-error {
        display: flex;
        justify-content: center;
        padding: 20px;
    }
}

.flex {
    display: flex;
    width: 100%;

    &.column {
        flex-direction: column;
    }
    &.between {
        justify-content: space-between;
    }
    &.center {
        justify-content: center;
    }
    &.middle {
        align-items: center;
    }
    &.start {
        align-items: flex-start;
    }
    &.end {
        justify-content: flex-end;
    }
    &.grow {
        > * {
            flex: 1 1;
            &:last-child {
                margin-right: 0;
            }
        }
    }

    &.bottom {
        align-items: flex-end;
    }

    .f1 {
        flex: 1;
    }
    .f2 {
        flex: 2;
    }
}
