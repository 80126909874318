.contact-history-overview {
    &-calls {
        background: white;
        padding: 14px 8px;
        border-top: 1px solid var(--neutral-grey-40);
        border-bottom: 1px solid var(--neutral-grey-40);
    }

    &__headers {
        border-bottom: 1px solid var(--neutral-grey-40);

        h2 {
            &:first-child {
                padding-left: 8px;
            }

            font-family: 'Rubik-Medium';
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            color: #37414b;
            padding: 14px 0;
            margin: 0;
            cursor: pointer;

            img {
                margin-left: 4px;
            }
        }
    }

    &__items {
        max-height: 405px;
        overflow-y: overlay;
    }

    .contact-history-item {
        padding: 15px 0;
        border-bottom: 1px solid var(--neutral-grey-40);
        cursor: pointer;

        &__value {
            &:first-child {
                padding-left: 8px;
            }

            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: var(--primary-black-default);
        }

        &-sentiment {
            height: 18px;
        }

        &-call-status {
            .call-status-wrapper {
                display: flex;
                align-items: center;

                .status-circle {
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
                    margin-right: 6px;
                }
                
                .green {
                    background-color: #11A58C;
                }
                
                .red {
                    background-color: #FF381B;
                }

            }
        }
    }
}

.flipped {
    transform: rotate(180deg);
}
