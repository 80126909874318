.contact-history-details {
    background-color: white;
    box-sizing: border-box;
    display: flex;
    max-height: 484px;
    line-height: 20px;

    .padding-side {
        padding: 0 16px;
    }

    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 10px 0;
    }

    &-calls {
        margin-top: 16px;
    }

    &-left,
    &-right {
        flex: 0 50%;
    }

    &-right {
        background: var(--neutral-grey-20);
        overflow-y: auto;
    }

    &__detail {
        padding-bottom: 14px;
        width: 50%;
        color: var(--outline-grey-100);

        &__label {
            font-weight: 600;
        }
    }

    &-audio {
        border-top: 1px solid var(--neutral-grey-40);
    }
}

.details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border-top: 1px solid var(--neutral-grey-40);
    border-bottom: 1px solid var(--neutral-grey-40);
    background: white;

    &-left {
        display: flex;
        align-items: center;
    }

    &__contact {
        span {
            font-family: 'Rubik-Medium';
        }
    }

    &__back {
        display: flex;
        align-items: center;
        margin-right: 14px;
        padding-right: 8px;

        border-right: 1px solid var(--neutral-grey-40);
        width: unset;
        height: 24px;

        img {
            margin-right: 8px;
        }
    }

    &-navigator {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid var(--neutral-grey-40);
        border-radius: 8px;

        .page-number {
            font-family: 'Rubik-Medium';
            font-size: 12px;
            padding: 0 8px;
            margin: 0;
        }

        .chevron-button {
            width: 30px;
            height: auto;

            &.left {
                border-right: 1px solid var(--neutral-grey-40);
            }

            &.right {
                border-left: 1px solid var(--neutral-grey-40);
            }
        }
    }
}

.contact-history-recordings {
    height: 165px;
    width: 100%;
}
