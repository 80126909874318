$tracks-container-height: 100px; // WARNING: THESE VALUES ARE USED IN JS FILES TOO
$track-height: $tracks-container-height / 2;

.row.grow .sa-audio-download {
    margin: 0 10px;
}

.waveform-tracks {
    width: 100%;
}

.sa-audio-download {
    max-width: 94px;
    min-width: 60px;
    border-radius: 4px;
    height: 28px;
    padding-left: 4px;
    border: 1px solid var(--neutral-grey-40);
    font-size: 12px;
    font-weight: 700;
    color: var(--neutral-grey-80);

    // change styles for the button when an audio file is downloading
    &.is-downloading {
        padding-left: 12px;
        font-size: 8px;
    }

    // download icon
    img {
        margin-right: 5px;
    }
}

.sa-audio-downloading-text {
    padding-left: 12px;
    font-size: 12px;
}

.sa-audio-download-spinner {
    width: 100%;
    display: flex;
    justify-content: center;
}

// current time and play/pause button
.sa-audioplayer {
    font-family: var(--roboto-regular);
    width: 100%;
    min-height: $tracks-container-height;

    &-tracks {
        display: flex;
        flex-direction: column;
    }

    &-controls {
        display: flex;
        align-items: center;

        span {
            padding: 0 3px;
        }

        .button {
            height: 28px;
            width: 28px;
            margin: 0 8px;
            border: 1px solid var(--neutral-grey-40);
            border-radius: 4px;

            img {
                height: 100%;
            }
        }

        &-wrapper {
            display: flex;
            justify-content: flex-end;
            font-size: 12px;
            color: var(--audio-player-wrapper);
        }
    }

    .seeker {
        width: 2px;
        background: var(--state-orange-default);
        height: $tracks-container-height;
        position: absolute;
        z-index: 1;

        // .seeker-wrapper - this is the parent
        &-wrapper {
            margin: 0 12px 0 62px; // 0, audio padding, 0, audio padding + mute button
            position: relative;
        }

        &::before {
            $top-circle-size: 10px;

            cursor: grabbing;
            content: '';
            background: inherit;
            border-radius: $top-circle-size;
            height: $top-circle-size;
            width: $top-circle-size;
            position: absolute;
            top: -$top-circle-size;
            right: -($top-circle-size / 2) + 1;
        }
    }

    footer {
        margin: 0 0 0 $track-height;
        padding: 8px 8px 12px 8px;
        font-size: 12px;
        color: var(--neutral-grey-100);
    }
}

// playback rate selection - override SA component style
.audio-player {
    padding-top: 6px;

    &-error {
        margin: 20px 0;
        min-height: 120px;
    }

    h3 {
        font-size: 14px;
        font-family: 'Rubik-Medium';
        font-weight: 500;
        margin-right: 20px;
        color: #4d5772;
    }

    .sa-dropdown-wrapper {
        min-width: 105px;
        max-width: 120px;
        margin-right: 7px;

        .sa-dropdown {
            font-size: 12px;
            font-weight: 600;
            color: var(--neutral-grey-80);
            padding-top: 2px;
            padding-bottom: 2px;
            border: 1px solid var(--neutral-grey-40);
            position: relative;

            .sa-dropdown-options {
                bottom: unset !important;
            }
        }
    }
}

.track-player-container {
    padding: 0 12px;
    width: 100%;

    .track-player {
        flex: 1 1;
        display: flex;
        align-items: center;
        height: $track-height;
        cursor: pointer;
        position: relative;
        width: 100%;

        &.no-waveform {
            .line-container {
                background-color: var(--neutral-grey-20);
                border: 1px solid var(--neutral-grey-80);
                border-radius: 8px;
                width: 100%;

                .line {
                    height: 7px;
                    background: var(--neutral-grey-80);
                }
            }
        }

        .blip {
            background: var(--neutral-grey-80);
        }

        &-background {
            margin: 0 $track-height;
            position: absolute;
            display: flex;
            height: $tracks-container-height;
            width: calc(100% - #{$tracks-container-height});

            .plank {
                width: 20px;
                margin-right: 5px;
                height: $track-height;
                background: var(--track-background-plank);
            }
        }
    }
}

.track {
    height: $track-height;
    background: #fff;
    position: relative;
    display: flex;
    border: 1px solid;
    border-color: var(--neutral-grey-40);

    .mute {
        height: $track-height;
        min-width: $track-height;
        max-width: $track-height;
        outline-offset: -5px;
        border-right: 1px solid;
        border-color: var(--neutral-grey-40);

        &:focus {
            background-color: var(--neutral-grey-60);
            outline-color: var(--neutral-white);
        }
        &:hover {
            background-color: var(--neutral-grey-60);
        }
    }

    &-2 {
        .mute {
            &:focus {
                background-color: rgba(59, 131, 191, 0.5);
                outline-color: var(--neutral-white);
            }
            &:hover {
                background-color: rgba(59, 131, 191, 0.5);
            }
        }

        .mute,
        .track-player-container {
            background: rgba(59, 131, 191, 0.1);
        }

        .plank {
            background: var(--primary-black-default);
            opacity: 0.9;
        }

        .blip {
            background: var(--neutral-white);
        }
    }
}
