.sa-transcript {
    box-sizing: border-box;
    padding: 10px;

    .message {
        display: flex;
        width: fit-content;
        max-width: 80%;
        margin: 5px 0;
        color: #fff;

        button {
            display: flex;
            font-family: 'Rubik-Regular';
            cursor: pointer;
            padding: 8px;
            text-align: left;
            font-size: 14px;
            box-shadow: 0px 2px 5px rgba(28, 26, 24, 0.1);

            &.focused {
                border: solid var(--state-orange-default) 2px;
            }
        }

        button span {
            text-align: initial;
        }

        &-timestamp {
            margin: 5px 0 0 5px;
            font-family: var(--roboto-regular);
            font-size: 12px;
            font-weight: normal;
        }

        &-sentiment {
            align-self: flex-end;
        }

        &.outgoing {
            align-self: flex-end;

            button {
                background-color: var(--state-lightblue-default);
                border-radius: 15px 15px 0px 15px;
            }

            .message-timestamp {
                align-self: flex-end;
                opacity: 0.7;
            }

            .message-sentiment {
                margin-left: 4px;
            }
        }

        &.incoming {
            align-self: flex-start;
            flex-direction: row-reverse;

            button {
                background-color: #fff;
                color: #000;

                border-radius: 15px 15px 15px 0px;
            }

            .message-timestamp {
                color: #000;
                align-self: flex-end;
            }

            .message-sentiment {
                margin-right: 4px;
            }
        }
    }

    &-no-data {
        display: flex;
        flex-direction: column;
        height: 100px;
        justify-content: center;
        align-items: center;

        img {
            margin-bottom: 20px;
        }
    }
}
